<!--
 * @Author: 申馨
 * @Date: 2023-10-20 09:52:21
 * @LastEditors: 申馨
 * @LastEditTime: 2023-10-21 17:39:16
 * @Description: file content
 * @FilePath: \hongan-h5\src\views\guidPay.vue
-->
<template>
  <div class="guipay">
    <div class="header_b">
      <div class="header_b_content">
        <img src="@/assets/image/logo1.png" alt="" class="logo" />
        <p class="interval"></p>
        <p style="font-size: 18px">收银台</p>
      </div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="content_son">
        <div class="son1">
          <p>订单提交成功，请尽快付款！</p>
          <p style="margin-top: 10rpx">订单号：{{ orderNo }}</p>
          <!-- <p style="text-align: right; margin-top: 20rpx">
            <text>应付总额：</text>
            <text style="color: #ff3c31; font-size: 44rpx"
              >￥{{ payMoney }}</text
            >
          </p> -->
        </div>
        <div class="son_details">
          <p class="son-p" v-if="isApply">第一步:长按识别图中二维码</p>
          <div class="code">
            <div class="code-left">
              <div class="qr" v-if="downloadData.url">
                <vue-qr
                  :text="downloadData.url"
                  :margin="10"
                  colorDark="#000"
                  colorLight="#fff"
                  :dotScale="1"
                  :logoSrc="downloadData.icon"
                  :logoScale="0.2"
                  :size="320"
                ></vue-qr>
              </div>
            </div>
            <img
              v-if="isApply"
              src="@/assets/image/yd1.png"
              alt=""
              srcset=""
              class="yd"
            />
            <img
              v-if="!isApply"
              src="@/assets/image/yd.png"
              alt=""
              srcset=""
              class="yd"
            />
          </div>
          <div class="tip" @click="getStatus('done')">
            <p>
              <text v-if="isApply">第二步:</text>已完成支付,点击查看我的报告
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { orderStatus } from '../api/getUserInfo';
import vueQr from 'vue-qr';
export default {
  components: {
    vueQr,
  },
  data() {
    return {
      downloadData: {
        url: '', //需要转化成二维码的网址
        icon: require('@/assets/image/apply.png'), //二维码中间的图片,可以不设置
      },
      orderNo: '',
      payMoney: '',
      fishTime: null,
      isApply: false,
    };
  },
  mounted() {
    const userAgent = navigator.userAgent.toLowerCase();
    const details = JSON.parse(decodeURIComponent(this.$route.query.details));
    console.log(details);
    this.orderNo = details.orderNo;
    this.downloadData.url = details.link;
    //支付宝环境下
    if (userAgent.indexOf('alipay') !== -1) {
      this.isApply = true;
    }
    this.fishTime = setInterval(() => {
      this.getStatus('polling');
      //银盛支付 需轮询查支付结果
    }, 1000);
  },
  beforeRouteLeave(to, from, next) {
    next();
    console.log('离开当前页面');
    clearInterval(this.fishTime);
    this.fishTime = null;
  },
  methods: {
    getStatus(type) {
      orderStatus({ orderNo: this.orderNo }).then((res) => {
        if (res.data.code == 200) {
          clearInterval(this.fishTime);
          this.fishTime = null;
          // 1是百融的接口 success页面
          if (res.data.data.porduct == 1) {
            setTimeout(() => {
              this.$router.push({
                path: '/success',
                query: {
                  orderNo: res.data.data.orderNo,
                  cid: res.data.data.cid,
                  templateId: res.data.data.templateId,
                },
              });
            }, 1000);
          } else {
            // 2 是天创的接口 显示天创的页面
            // res.data.data.porduct==2
            setTimeout(() => {
              this.$router.push({
                path: '/prosperity',
                query: {
                  orderNo: res.data.data.orderNo,
                  cid: res.data.data.cid,
                  templateId: res.data.data.templateId,
                },
              });
            }, 1000);
          }
        } else {
          if (type == 'done') {
            if (err.code == 500) {
              Toast(err.msg);
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.guipay {
  width: 100%;
  height: 100vh;
  background-color: #efefef;
}
.header_b {
  width: 100%;
  height: 116px;
  background: #ffffff;

  .header_b_content {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    font-size: 20px;
    color: #515a6e;
    display: flex;
    align-items: center;

    .logo {
      width: 58px;
      height: 58px;
      margin-left: 20px;
    }

    .interval {
      display: block;
      width: 2px;
      height: 48px;
      opacity: 0.2;
      border: 1px solid #000000;
      margin: 0 34px 0 24px;
    }
  }
}
.content {
  width: 100%;

  .content_son {
    width: 100%;
    .son_details {
      width: 100%;
      min-height: 500px;
      background: #ffffff;
      // margin-top: 30rpx;
      padding: 32px 20px;
      box-sizing: border-box;
    }
    .son-p {
      color: red;
      font-weight: 700;
      margin-left: 20px;
    }
  }
}
.qr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    width: 70%;
  }
}
.code {
  width: 100%;
  padding-top: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .code-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .yd {
    width: 45%;
    height: 280px;
    margin-top: -108px;
    margin-left: -20px;
  }
}

.tip {
  // width: 220rpx;
  height: 70px;
  background: #296df1;
  margin-top: 36px;
  font-size: 28px;
  color: #ffffff;
  padding: 10px 16px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 68px;
    height: 68px;
    margin-right: 40px;
  }
}

.son1 {
  padding: 0 32px;
  padding-top: 40px;
  padding-bottom: 32px;
  box-sizing: border-box;
  font-size: 24px;
  // background-color:#efefef ;
}
</style>
